import type { PopoverProps } from 'antd/es'
import { Popover } from 'antd/es'
import type { FC, ReactNode } from 'react'

import { selectWithLayover } from '@redux/features/search/search.selectors'
import { useAppSelector } from '@redux/hooks'

import {
  searchFormSizes,
  topPartHeightDesktop,
} from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/sizes'

import { passengersLabelRootClassName, rootClassName } from '../../constants/classNames'
import { GlobalStyles } from './styles'

type Props = {
  close: () => void
  formContent: ReactNode
  isOpened: boolean
  labelContent: ReactNode
  open: () => void
  popoverProps?: PopoverProps
}

const DesktopContent: FC<Props> = ({ close, formContent, isOpened, labelContent, open, popoverProps }) => {
  const withLayover = useAppSelector(selectWithLayover)

  return (
    <>
      <Popover
        align={{
          overflow: { shiftX: 0, shiftY: 0 },
          targetOffset: [-20, topPartHeightDesktop + (withLayover ? searchFormSizes.layoversHeightDesktop : 0)],
        }}
        arrow={false}
        autoAdjustOverflow={true}
        className={passengersLabelRootClassName}
        content={formContent}
        destroyTooltipOnHide={false}
        getPopupContainer={node => node}
        onOpenChange={value => (value ? open() : close())}
        open={isOpened}
        overlayInnerStyle={{ background: 'none', boxShadow: 'none', padding: 0 }}
        placement="rightTop"
        rootClassName={rootClassName}
        trigger="click"
        {...(popoverProps || {})}
      >
        <>
          {labelContent}
          <GlobalStyles />
        </>
      </Popover>
      {!isOpened && formContent}
    </>
  )
}

export default DesktopContent
